import { Component } from '@angular/core';
import { NavigationService } from './services/navigation.service';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.css'],
  template: ` <router-outlet></router-outlet> `,
})
export class AppComponent {
  title = 'Tibo-Home';
  constructor(private navigationService: NavigationService) {
    this.navigationService.init();
  }
}

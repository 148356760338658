import { Component, Inject, OnInit } from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

export interface AlertData {
  message:string,
  title:string,
  type:AlertType
}

export enum AlertType {
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error'
}

@Component({
  selector: 'app-custom-alerts',
  templateUrl: './custom-alerts.component.html',
  styleUrls: ['./custom-alerts.component.css']
})
export class CustomAlertsComponent implements OnInit {
  iconName: string = 'warning_amber'
  constructor(
    private snackBarRef:MatSnackBarRef<CustomAlertsComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: AlertData
  ) { }

  ngOnInit(): void {
    switch (this.data.type) {
      case AlertType.WARNING:
        this.iconName = 'warning_amber'
        break;
      case AlertType.SUCCESS:
        this.iconName = 'check_circle'
      case AlertType.ERROR:
        this.iconName = 'error'
      default:
        break;
    }
  }

  close(){
    this.snackBarRef.dismiss()
  }

}
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertsService } from "./alerts.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

export declare enum HttpStatusEnum {
    CONTINUE = 100,
    SWITCHING_PROTOCOLS = 101,
    PROCESSING = 102,
    EARLYHINTS = 103,
    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,
    NON_AUTHORITATIVE_INFORMATION = 203,
    NO_CONTENT = 204,
    RESET_CONTENT = 205,
    PARTIAL_CONTENT = 206,
    AMBIGUOUS = 300,
    MOVED_PERMANENTLY = 301,
    FOUND = 302,
    SEE_OTHER = 303,
    NOT_MODIFIED = 304,
    TEMPORARY_REDIRECT = 307,
    PERMANENT_REDIRECT = 308,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PAYMENT_REQUIRED = 402,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    NOT_ACCEPTABLE = 406,
    PROXY_AUTHENTICATION_REQUIRED = 407,
    REQUEST_TIMEOUT = 408,
    CONFLICT = 409,
    GONE = 410,
    LENGTH_REQUIRED = 411,
    PRECONDITION_FAILED = 412,
    PAYLOAD_TOO_LARGE = 413,
    URI_TOO_LONG = 414,
    UNSUPPORTED_MEDIA_TYPE = 415,
    REQUESTED_RANGE_NOT_SATISFIABLE = 416,
    EXPECTATION_FAILED = 417,
    I_AM_A_TEAPOT = 418,
    MISDIRECTED = 421,
    UNPROCESSABLE_ENTITY = 422,
    FAILED_DEPENDENCY = 424,
    PRECONDITION_REQUIRED = 428,
    TOO_MANY_REQUESTS = 429,
    INTERNAL_SERVER_ERROR = 500,
    NOT_IMPLEMENTED = 501,
    BAD_GATEWAY = 502,
    SERVICE_UNAVAILABLE = 503,
    GATEWAY_TIMEOUT = 504,
    HTTP_VERSION_NOT_SUPPORTED = 505
}
@Injectable({
    providedIn: 'root'
})
export class HttpIntercept implements HttpInterceptor {
    constructor(
        private alerts: AlertsService
    ){}
    intercept(
        request:HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>>
    {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse)=>{
                if(error instanceof HttpErrorResponse){
                    let errorString = ''
                    if(error.error && error.error.message){
                        let message = error.error.message
                        let lowerCaseString = Array.isArray(message) ? message.join(', ') : message
                        errorString = lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
                    } 
                    this.alerts.showToastError('Error '+error.status, errorString)
                    return throwError(error)
                }else{  
                    return throwError(error)
                }
            })
        )
    }
}
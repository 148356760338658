import { NgModule } from "@angular/core";
import { CustomAlertsComponent } from './custom-alerts/custom-alerts.component';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import {MatIconModule} from '@angular/material/icon';
import { CommonModule } from "@angular/common";
@NgModule({
    declarations: [CustomAlertsComponent],
    imports: [
        CommonModule,
        MatSnackBarModule,
        MatIconModule
    ],
    exports:[CustomAlertsComponent],
})

export class SharedModule{}
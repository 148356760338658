import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private http: HttpClient,
    ) { }

    public setToken(token: string){
        localStorage.setItem('ACCESS_TOKEN', token);
    }

    public getToken(){
        return localStorage.getItem('ACCESS_TOKEN');
    }


    public isLoggedIn(): boolean{
        const token = localStorage.getItem('ACCESS_TOKEN')
        return token != null ? true : false
    }

    public logout(){
        localStorage.removeItem('ACCESS_TOKEN')
    }

}
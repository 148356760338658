import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { AlertType, CustomAlertsComponent } from '../shared/custom-alerts/custom-alerts.component';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  constructor (private snackBar:MatSnackBar) {}

  showToastError(
    title = 'Error',
    message = ''
  ){
    this.snackBar.openFromComponent(CustomAlertsComponent,{
      data: {title, message, type:AlertType.ERROR},
      duration:10000,
      verticalPosition:'top' as MatSnackBarVerticalPosition,
    });
  }

  showToastSuccess(
    title = 'Success',
    message = ''
  ){
    this.snackBar.openFromComponent(CustomAlertsComponent,{
      data: {title, message, type:AlertType.SUCCESS},
      duration:10000,
      verticalPosition:'top' as MatSnackBarVerticalPosition,
    });
  }

  showToastWarning(
    title = 'Warning',
    message = ''
  ){
    this.snackBar.openFromComponent(CustomAlertsComponent,{
      data: {title, message, type:AlertType.WARNING},
      duration:10000,
      verticalPosition:'top' as MatSnackBarVerticalPosition,
    });
  }
}
